import { useEffect, useState } from "react";
import AccountStatusesLayout from "./components/AccountStatusesLayout";
import AccountStatusesTable from "./components/AccountStatusesTable";
import { getAccountStatuses } from "../../../services/reports";
import { Money, Utils } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import {
  setAccountStatuses,
  setFetchingAccountStatuses,
} from "../../../features/administracion/administracionSlice";
import Comprobantes from "./components/Comprobantes";

export default function AccountStatusesTab() {
  const { fetchingAccountStatuses, accountStatuses } = useSelector(
    (state) => state.administracion
  );
  const [accountStatusesPeriod, setAccountStatusesPeriod] = useState({
    start: "",
    end: "",
  });
  const [totales, setTotales] = useState({
    aPagar: 0,
    pagado: 0,
    diferencia: 0,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    fetchAccountStatuses();
  }, []);

  useEffect(() => {
    calcularTotales();
  }, [accountStatuses]);

  const fetchAccountStatuses = async () => {
    try {
      dispatch(setFetchingAccountStatuses(true));

      const _accountStatuses = await getAccountStatuses();

      if (!_accountStatuses || _accountStatuses.length === 0) {
        setTotales({ aPagar: 0, pagado: 0, diferencia: 0 });
        dispatch(setAccountStatuses([]));
        setAccountStatusesPeriod({
          start: "",
          end: "",
        });
        return;
      }

      dispatch(setAccountStatuses(_accountStatuses));
      setAccountStatusesPeriod(_accountStatuses[0].period);
    } catch (error) {
      console.error("Error al obtener estados de cuenta:", error.message);
    } finally {
      dispatch(setFetchingAccountStatuses(false));
    }
  };

  const calcularTotales = () => {
    // Calcular totales usando reduce()
    const { totalApagar, totalPagado } = accountStatuses.reduce(
      (acc, item) => {
        acc.totalApagar += item.amount;
        acc.totalPagado += item.deposits.list.reduce(
          (sum, el) => sum + parseFloat(el.pago),
          0
        );
        return acc;
      },
      { totalApagar: 0, totalPagado: 0 }
    );

    setTotales({
      aPagar: totalApagar,
      pagado: totalPagado,
      diferencia: totalApagar - totalPagado,
    });
  };

  return (
    <AccountStatusesLayout
      sectionTitle={
        fetchingAccountStatuses
          ? "cargando estados de cuenta"
          : "Estados de cuenta"
      }
      sectionSubTitle={
        fetchingAccountStatuses ? null : (
          <div>
            <p>{Utils.periodToLongText(accountStatusesPeriod)}</p>
            <p>Total a pagar: {Money(totales.aPagar)}</p>
            <p>Total pagado: {Money(totales.pagado)}</p>
            <p>
              {totales.diferencia > 0 ? "Faltante:" : "Sobrante:"}{" "}
              {Money(Math.abs(totales.diferencia))}
            </p>
          </div>
        )
      }
    >
      {!fetchingAccountStatuses && (
        <>
          <AccountStatusesTable />
          {/* COMPROBANTES */}
          <Comprobantes />
        </>
      )}
    </AccountStatusesLayout>
  );
}
