import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ScrollView from "./ScrollView";
import emptyImg from "../assets/no-results.png";
import CustomSelect from "./CustomSelect";
import { getUsers } from "../services/common";

export default function Tabla({
  headData = [],
  data = [],
  renderItem,
  withFilter = false,
}) {
  const [filtered, setFiltered] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [selectValue, setSelectValue] = useState("");

  useEffect(() => {
    setFiltered([...data]);
  }, [data]);

  useEffect(() => {
    if (withFilter) {
      fetchUsuarios();
    }
  }, []);

  const fetchUsuarios = async () => {
    try {
      const _data = await getUsers();
      _data.unshift({
        id: "todo",
        nomComercial: "Todo",
      });
      setUsuarios(_data);
      setSelectValue("todo");
    } catch ({ message }) {
      console.log(message);
    }
  };

  const handleChange = (value) => {
    if (value == "todo") {
      setFiltered([...data]);
      setSelectValue(value);
      return;
    }
    const user = usuarios.find((user) => user.id === value);
    const premiosPendientes = [...data].filter(
      (item) => item.usuario === user.usuario
    );
    setFiltered(premiosPendientes);
    setSelectValue(value);
  };

  if (data.length === 0)
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={emptyImg} style={{ width: 200 }} />
        <Typography>sin resultados</Typography>
      </Box>
    );
  return (
    <>
      {/* FILTRO */}
      {withFilter && (
        <Box sx={{ marginBottom: 2 }}>
          <CustomSelect
            width={200}
            label="filtrar"
            options={usuarios}
            optionLabel="nomComercial"
            optionValue="id"
            onChange={handleChange}
            value={selectValue}
          />
        </Box>
      )}
      <ScrollView scrollBoth maxHeight={600}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {headData.map((colName) => (
                <TableCell key={colName}>{colName}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{filtered.map(renderItem)}</TableBody>
        </Table>
      </ScrollView>
    </>
  );
}
